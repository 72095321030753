@import '@app/acp/globalStyles/account.vars';

.payment-method-preview {
    margin-top: $margin-md;

    .sparky-c-media-block {
        .sparky-c-media-block__body {
            margin-left: $margin-sm;
            justify-content: center;
        }

        .sparky-c-media-block__header {
            .sparky-c-icon {
                width: 32px;
                height: 20px;
            }
        }
    }
}
