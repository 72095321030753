@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.custom-filter-refinement-list {
    font-family: var(--sparky-font-family-primary);
    font-size: var(--sparky-font-size-12);
    position: relative;

    @media screen and (max-width: $sparky-bp-md) {
        max-height: calc(100vh - 185px);
    }

    &__href {
        text-decoration: none;
        color: var(--sparky-color-neutral-900);
    }

    &__search-box {
        @media screen and (min-width: $sparky-bp-md) {
            padding-left: size(2);
            padding-right: size(1.5);
        }

        position: absolute;
        width: calc(100% - size(0.5));
        background-color: var(--sparky-color-neutral-white);
        z-index: 1;

        input {
            font-family: var(--sparky-font-family-primary);
            font-size: var(--sparky-font-size-16);
            color: var(--sparky-color-neutral-900);
            width: 100%;
            padding: size(2) size(2) size(1.5) size(2);
            border-radius: var(--sparky-border-radius-md);
            border: solid var(--sparky-border-width-md)
                var(--sparky-color-neutral-200);

            &:hover,
            &:focus,
            &:focus-visible {
                outline: none;
                border: solid var(--sparky-border-width-md)
                    var(--sparky-color-other-focus-3);
            }

            &::placeholder {
                color: var(--sparky-color-neutral-900);
            }

            &::-webkit-search-cancel-button {
                display: none;
            }
        }

        button {
            display: none;
        }
    }

    &__container {
        padding: 0;
        flex-direction: column;
        overflow-y: auto;
        margin-top: 0;
        list-style: none;

        &__li {
            list-style: none;
            padding: to-rem(3px) to-rem(7px);

            &:hover {
                background-color: var(--sparky-color-brand-blue-50);
            }
        }

        &--has-show-more {
            &::-webkit-scrollbar-thumb {
                display: none;
            }
        }

        &--has-search-box {
            padding-top: to-rem(63px) !important;
        }

        &::-webkit-scrollbar {
            width: size(0.5);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(100 100 100 / 80%);
            border-radius: var(--sparky-border-radius-md);
        }
    }

    &__no-results {
        padding: to-rem(20px) to-rem(12px);
        text-align: center;
        font-size: var(--sparky-font-size-14);

        @media screen and (max-width: $sparky-bp-md) {
            height: calc(100vh - 300px);
            max-height: calc(100vh - 300px);
        }

        &.has-search-box {
            padding-top: 63px !important;
        }
    }

    &__item {
        display: flex;
        padding-top: size(0.5);
        padding-bottom: size(0.5);
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        font-size: var(--sparky-font-size-14);
        color: var(--sparky-color-neutral-900);

        &__count {
            padding-left: size(0.5);

            @media screen and (max-width: $sparky-bp-sm-2) {
                margin-left: 0;
            }
        }

        @media screen and (min-width: $sparky-bp-md) {
            padding-left: size(1.5);
            padding-right: size(1.5);
        }

        &--selected {
            margin-right: to-rem(7px);
            width: size(2.5);
            height: size(2.5);
        }

        &:hover {
            background-color: var(--sparky-color-brand-blue-50);
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--sparky-color-neutral-100);
        padding: size(2) 0 0;
        position: relative;
        justify-content: flex-end;

        @media screen and (min-width: $sparky-bp-md) {
            padding-left: size(1.75);
            padding-right: size(1.75);
        }

        &::before {
            left: calc(size(2) * -1);
        }

        &::after {
            right: calc(size(2) * -1);
        }

        &::before &::after {
            content: '';
            position: absolute;
            top: -1px;
            width: size(2);
            height: 1px;
            background-color: var(--sparky-color-neutral-100);

            @media screen and (min-width: $sparky-bp-md) {
                display: none;
            }
        }

        &--reset {
            border-radius: var(--sparky-theme-border-radius);
            font-size: var(--sparky-font-size-14);
            color: var(--sparky-color-neutral-600);
            display: none;

            &:focus,
            &:hover {
                outline: none;
                text-decoration: none;
            }

            @media screen and (min-width: $sparky-bp-md) {
                display: flex;
            }
        }

        &--view {
            height: to-rem(48px) !important;

            .sparky-c-button__text {
                color: var(
                    --sparky-theme-color-button-primary-foreground
                ) !important;
                font-size: to-rem(16px);
                line-height: to-rem(24px);
            }
        }

        .sparky-c-button--link:disabled {
            background-color: transparent;
        }
    }

    &__show-more {
        font-size: var(--sparky-font-size-14);
        line-height: to-rem(24px);

        &:focus {
            outline: none;
        }
    }

    &.non-drawer-filter {
        max-height: calc(100vh - 300px);
        overflow: hidden;
        overflow-y: scroll;
    }

    &.brand {
        .filter-refinement-list__container {
            @media screen and (max-width: $sparky-bp-md) {
                height: calc(100vh - 250px);
                max-height: calc(100vh - 250px);
            }
        }
    }
}
