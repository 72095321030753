@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.error-not-found {
    margin-left: size(2);
    margin-right: size(2);

    @media all and (min-width: $sparky-bp-md) {
        margin-left: size(4);
        margin-right: size(4);
    }

    @media all and (min-width: $sparky-bp-lg) {
        margin: auto;
    }

    &__text {
        @media all and (min-width: $sparky-bp-md) {
            max-width: to-rem(370px);
        }

        @media all and (min-width: $sparky-bp-lg) {
            max-width: to-rem(410px);
        }
    }

    &__picture {
        display: block;
        margin: auto;
        width: to-rem(250px);

        @media all and (min-width: $sparky-bp-md) {
            width: to-rem(350px);
        }

        @media all and (min-width: $sparky-bp-lg) {
            width: to-rem(400px);
        }
    }

    &__header {
        margin-bottom: size(2);

        .sparky-c-page-header__title {
            font-size: 36px;
            line-height: 44px;

            @media all and (min-width: $sparky-bp-lg) {
                font-size: 40px;
                line-height: 48px;
            }
        }

        .sparky-c-page-header__description {
            margin-top: size(2);

            @media all and (min-width: $sparky-bp-md) {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }

    &__button {
        width: 100%;

        @media all and (min-width: $sparky-bp-lg) {
            width: auto;
            padding: 14px 24px;
        }
    }
}
