@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@app/acp/globalStyles/account.vars';

.adyen-form {
    #adyen-card-container {
        font-family: euclid, sans-serif;

        .adyen-checkout__card-input {
            .adyen-checkout-form-instruction,
            .adyen-checkout__label {
                display: none;
            }

            .adyen-checkout__input-wrapper {
                .adyen-checkout__input {
                    min-height: to-rem(56px);
                    height: to-rem(56px);
                    padding: 0 to-rem(14px);
                    font-size: to-rem(16px);
                    font-weight: 400;
                    border-radius: to-rem(4px);
                    border-color: $border-medium-grey;
                    color: var(--sparky-color-neutral-900);

                    > img {
                        right: to-rem(12px);
                    }

                    &::placeholder {
                        color: var(--sparky-color-neutral-900);
                    }

                    &:hover {
                        border-color: var(
                            --sparky-theme-color-input-border-hover
                        );
                    }

                    &.adyen-checkout__input--error {
                        border: 1px solid
                            var(--sparky-theme-color-utility-error-border);

                        &.adyen-checkout__input--focus,
                        &:focus {
                            box-shadow: none;
                        }
                    }

                    &:not(.adyen-checkout__input--error) {
                        &.adyen-checkout__input--focus,
                        &:focus {
                            outline: 1px solid
                                var(--sparky-theme-color-focus-ring);
                            border-color: transparent;
                            box-shadow: none;
                        }
                    }
                }

                span:not(.adyen-checkout__input) {
                    > img {
                        display: none;
                        margin: 0 to-rem(12px) 0 0;

                        @media all and (min-width: $desktop-min) {
                            display: block;
                        }
                    }
                }

                .adyen-checkout__card__cvc__hint__wrapper {
                    display: none;
                    margin: 0 to-rem(12px);

                    @media all and (min-width: $desktop-min) {
                        display: flex;
                    }
                }
            }

            .adyen-checkout__error-text {
                padding-left: to-rem(15px);
                color: var(--sparky-theme-color-utility-error-border);
            }

            .adyen-checkout__field {
                margin-bottom: to-rem(12px);

                &.adyen-checkout__field--error {
                    .adyen-checkout__input {
                        border: 1px solid
                            var(--sparky-theme-color-utility-error-border);
                    }
                }
            }

            .adyen-checkout__field-wrapper {
                &:last-of-type {
                    > .adyen-checkout__field {
                        margin-bottom: 0;
                    }
                }
            }

            :not(.adyen-checkout__field-group) {
                > .adyen-checkout__field:not(.adyen-checkout__field__cvc) {
                    &:last-of-type {
                        margin: to-rem(12px) 0 0;
                    }
                }
            }
        }

        .adyen-checkout__card__brands {
            display: none;
        }
    }
}
