@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@app/acp/globalStyles/account.vars';

.remove-card-modal.sparky-c-modal {
    z-index: 11; // We have to have higher z-index than 10 (side menu), otherwise visual glitch is present when closing the modal

    &:not(.sparky-c-modal--full-screen) {
        .sparky-c-modal__window {
            width: to-rem(604px);
        }
    }

    .sparky-c-modal__body {
        .sparky-c-heading {
            margin-bottom: $margin-sm;
        }

        .sparky-c-text-passage {
            line-height: var(--sparky-line-height-xxxxl);

            &.card-remove-blocked {
                line-height: var(--sparky-line-height-xxxl);
            }
        }
    }

    .sparky-c-modal__footer {
        margin-top: to-rem(24px);

        .sparky-c-button-group {
            flex-direction: column;

            @media all and (min-width: $sparky-bp-sm-2) {
                flex-direction: row;
            }
        }

        .sparky-c-button--primary.sparky-c-button--lg.danger-btn {
            background: var(--sparky-color-brand-red-700);
            border-color: var(--sparky-color-brand-red-700);

            &:hover {
                background: var(--sparky-color-other-red)
                    radial-gradient(
                        circle,
                        transparent 1%,
                        var(--sparky-color-other-red) 1%
                    )
                    center / 15000%;
                border-color: var(--sparky-color-other-red);
            }

            &:focus {
                background: var(--sparky-color-brand-red-900)
                    radial-gradient(
                        circle,
                        transparent 0.5%,
                        var(--sparky-color-brand-red-900) 0.5%
                    )
                    center / 15000%;
                outline: 1px var(--sparky-color-other-red);
            }
        }
    }

    .sparky-c-button-group--align-right {
        align-items: flex-end;
    }
}
