@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.product-grid {
    &__results-count {
        font-family: var(--sparky-font-family-primary);
        font-size: var(--sparky-font-size-16);
        margin-left: to-rem(8px);
        height: to-rem(20px);
        line-height: to-rem(24px);
        white-space: nowrap;
    }

    &__card-footer {
        &--out-of-stock {
            color: var(--sparky-theme-color-utility-error-foreground);
            font-weight: var(--sparky-font-weight-medium);
        }
    }

    &__container {
        width: 100%;

        .sparky-c-product-card__star-rating {
            .sparky-c-star {
                stroke: var(--sparky-color-brand-yellow-700, transparent);
                fill: var(--sparky-color-brand-yellow-700, transparent);
            }
        }

        .result-text-container {
            padding: size(4) 0;
            display: flex;
            align-items: center;

            .product-grid__heading {
                height: to-rem(25px);
            }
        }

        .sparky-l-grid--4up.sparky-l-grid--divider > .sparky-l-grid__item {
            @media all and (max-width: $sparky-bp-sm-2) {
                padding-top: to-rem(19px);
                padding-right: to-rem(12px);
                padding-left: to-rem(12px);
            }
        }
    }

    .sparky-c-card__body {
        flex: initial;
    }

    .sparky-c-product-card__meta {
        min-height: size(2);
    }

    @media screen and (min-width: $sparky-bp-md) {
        .sparky-c-product-card__header {
            &:not(:has(.sparky-c-product-card__meta)) {
                padding-bottom: to-rem(20px);
            }
        }

        .sparky-c-product-card__body {
            height: to-rem(58px);

            .sparky-c-sparky-text-wrapper {
                -webkit-line-clamp: 3;
            }
        }

        .sparky-c-product-card__footer {
            min-height: to-rem(52px);

            &:not(:has(.sparky-c-product-card__star-rating)) {
                justify-content: end;
            }

            .sparky-c-price__strike-group {
                height: to-rem(20px);
            }
        }
    }

    // fixed accessibility mode issue CEC-2208
    .sparky-c-price--data .sr-only {
        display: none;
    }
}

body {
    .sparky-c-heading.sparky-c-heading--headline.product-grid {
        &__heading-brands,
        &__heading::first-letter {
            text-transform: capitalize !important;
        }
    }
}
