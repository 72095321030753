/* stylelint-disable declaration-empty-line-before */

@import 'src/account-and-customer-profile/src/globalStyles/account.vars';

@mixin offer-card-body-base {
    display: grid;
    grid-gap: calc(var(--sparky-size-base-unit) * 2);
    align-content: space-between;
}

@mixin offer-card-footer-flag {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;

    margin-top: 0;
}

@mixin align-circle-button {
    display: grid;
    align-content: center;
    align-items: center;

    width: $offer-card-button-height-circle;
    height: $offer-card-button-height-circle;

    padding: $padding-zero;
}

.c-offer-card {
    border: $section-border;
    border-radius: calc(var(--sparky-size-base-unit));

    &:hover .sparky-c-text-passage {
        text-decoration: underline;
        cursor: pointer;
    }

    &:hover .sparky-c-text-passage--lg {
        text-decoration-thickness: 2px;
    }

    &.c-offer-card-featured {
        width: $offer-image-width-featured;
        outline: none;

        @media all and (min-width: ($desktop-min)) {
            width: 100%;
            max-width: $offer-image-width-featured;
        }

        .sparky-c-card__header {
            display: flex;
            justify-content: center;
            align-items: center;

            max-height: $offer-image-height-featured;
            aspect-ratio: 286 / 123;
            margin-bottom: $margin-zero;

            background-color: $section-background-grey;

            img {
                @include set-max-width-and-max-height(100%);
            }
        }

        .sparky-c-card__body {
            @include offer-card-body-base;

            padding: calc(var(--sparky-size-base-unit) * 2);

            .offer-card-content {
                display: grid;
                grid-gap: calc(var(--sparky-size-base-unit) * 0.5);
            }

            .sparky-c-card__footer {
                display: grid;
                grid-gap: calc(var(--sparky-size-base-unit) * 2);

                margin-top: $margin-zero;

                button {
                    height: $offer-card-button-height;
                }
            }
        }
    }

    &.c-offer-card-large {
        display: grid;
        grid-template-rows: auto 1fr;

        height: $offer-card-height-large-mobile;
        width: $offer-card-width-mobile;

        @media all and (min-width: $desktop-large-min) {
            grid-template-columns: $offer-image-width-large-desktop 1fr;

            height: $offer-card-height;
            width: $offer-card-width-large-desktop;
        }

        @media all and (max-width: $mobile-xs-max) {
            width: 100%;
        }

        .sparky-c-card__header {
            display: grid;
            align-items: center;
            justify-content: center;

            height: $offer-image-height-large-mobile;

            background-color: $section-background-grey;
            overflow: hidden;

            @media all and (min-width: $desktop-min) {
                @include set-width-and-height(100%);
            }

            img {
                height: auto;
            }
        }

        .sparky-c-card__body {
            @include offer-card-body-base;

            height: $offer-card-height;
            padding: calc(var(--sparky-size-base-unit) * 2);

            @media all and (min-width: $desktop-min) {
                height: 100%;
            }

            @media all and (max-width: $mobile-xs-max) {
                height: 100%;
            }

            .offer-card-content {
                display: grid;
                grid-gap: calc(var(--sparky-size-base-unit));
                grid-template-rows: auto 1fr;
            }

            .sparky-c-card__footer {
                @include offer-card-footer-flag;

                height: $offer-card-button-height-circle;
            }
        }
    }

    &.c-offer-card-small {
        height: $offer-card-height;
        width: $offer-card-width-mobile;
        padding: calc(var(--sparky-size-base-unit) * 2);

        @media all and (min-width: $desktop-large-min) {
            width: $offer-card-width-small-desktop;
        }

        .sparky-c-card__body {
            @include offer-card-body-base;

            height: $offer-card-content-height;

            .offer-card-content {
                display: grid;
                grid-template-columns: auto auto;
                grid-gap: calc(var(--sparky-size-base-unit) * 2);
                align-content: start;

                img {
                    @include set-width-and-height($offer-image-width-small);
                    background-color: $section-background-grey;
                    border-radius: calc(var(--sparky-size-base-unit));
                }

                .offer-card-content-inner {
                    display: grid;
                    grid-gap: calc(var(--sparky-size-base-unit));
                    align-content: start;

                    .sparky-c-badge {
                        width: fit-content;
                    }
                }
            }

            .sparky-c-card__footer {
                @include offer-card-footer-flag;

                height: $offer-card-button-height-circle;
            }
        }
    }

    .c-offer-card-button {
        &.sparky-c-button--circle {
            @include align-circle-button;
        }

        &.offer-card-button-full-width {
            padding: $padding-zero;
        }
    }
}
