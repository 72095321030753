@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@app/acp/globalStyles/account.vars';

.billing-address-selector {
    margin-bottom: $margin-sm;

    @media all and (min-width: $desktop-min) {
        margin-bottom: $margin-md;
    }

    .sparky-c-radio-field__body {
        margin-bottom: 0;
    }

    .sparky-c-radio-field__list {
        gap: to-rem(12px);
    }

    .sparky-c-radio-field__item {
        margin-bottom: to-rem(1px);

        &:last-child {
            margin-bottom: 0;
        }

        &-before {
            width: to-rem(266px);
        }
    }

    .sparky-c-radio-field__item-body {
        align-items: center;

        .sparky-c-editable-section {
            margin-left: 0;
            margin-right: 0;
            padding: 0;

            &__button {
                font-size: var(--sparky-font-size-14);
            }
        }
    }

    .sparky-c-radio-field__item-label {
        margin-bottom: 0;
    }
}
