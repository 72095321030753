@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.sort-product {
    &__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: to-rem(11px) to-rem(8px);
        cursor: pointer;
        width: 100%;
        border: none;
        background: transparent;
        font-family: var(--sparky-font-family-primary);
        font-size: var(--sparky-font-size-14);
        color: var(--sparky-color-neutral-900);
        height: to-rem(40px);

        @media all and (max-width: $sparky-bp-md) {
            padding: to-rem(11px) to-rem(10px);
        }

        &:hover {
            background-color: #206ef60a;
        }

        &--label {
            line-height: to-rem(18px);
            margin-left: to-rem(4px);

            @media all and (max-width: $sparky-bp-md) {
                line-height: to-rem(20px);
                margin-left: to-rem(10px);
            }
        }

        &--not-choice {
            min-height: to-rem(20px);
            min-width: to-rem(20px);
        }

        &--checked {
            @media all and (min-width: $sparky-bp-lg) {
                margin-bottom: to-rem(-3px);
            }
        }
    }
}
