@import '@app/acp/globalStyles/account.vars';

.earned-points {
    display: flex;
    margin-top: 14px;

    .view-points-link {
        margin-left: auto;
    }
}
