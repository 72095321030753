@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.searchable-banner {
    .promo-banner,
    .tile6up {
        margin-bottom: size(4);
        margin-top: size(4);

        @media screen and (max-width: $sparky-bp-md) {
            margin-bottom: size(2);
            margin-top: size(2);
            padding: size(2) 0;
        }
    }
}
