@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.product-pagination {
    margin: size(4) 0;

    @media all and (min-width: $sparky-bp-xl) {
        margin: size(8) 0;
    }

    .sparky-c-pagination__link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: size(5);
        height: size(5);
        padding: 0;

        @media all and (max-width: $sparky-bp-xl) {
            width: size(5);
        }

        &:focus {
            outline: none;
        }
    }
}
