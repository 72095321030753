@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@app/acp/globalStyles/account.vars';

.about-you-modal {
    font-family: var(--sparky-font-family-primary) !important;
    z-index: 10;

    .sparky-c-radio-field__list {
        gap: to-rem(8px);
    }

    &__footer {
        &__cta {
            font-size: to-rem(16px);

            &:focus {
                outline: none;
            }
        }
    }

    &__description {
        font-size: to-rem(14px);
    }

    &__interaction {
        display: flex;

        .sparky-c-radio {
            margin-right: to-rem(10px);
        }
    }

    &__multiple {
        display: flex;
        align-items: center;

        .sparky-c-radio {
            margin-right: to-rem(10px);
        }
    }

    &__verified,
    &__no-matches {
        padding-top: to-rem(10px);
    }

    @media all and (max-width: $sparky-bp-sm-2) {
        &__footer {
            .sparky-c-button-group {
                width: 100%;
                flex-direction: column;
            }

            &__cta {
                padding: to-rem(14px) 0;
            }
        }
    }
}

.modal-item {
    &--highlight {
        color: #b45303 !important;
    }

    p {
        margin: 0;
        font-size: to-rem(14px);
        text-align: left;
        letter-spacing: 0;
        color: #131313;
        opacity: 1;
    }
}
