.breadcrumbs__item--ellipsis {
    &::before {
        content: '...';
    }

    & > a {
        display: none;
    }
}

.breadcrumbs__item--truncate {
    & > a {
        max-width: 23.4375rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.breadcrumbs__item--hidden {
    display: none;
}
