@import '~common-next/styles/functions';

.street-address-line {
    position: relative;

    &__dropdown {
        width: 100%;
        display: flex;
        margin-top: to-rem(1px);
        flex-direction: column;
        padding: to-rem(8px) to-rem(16px);
        position: absolute;
        max-height: to-rem(252px);
        overflow-y: scroll;
        scrollbar-width: thin;
    }

    &__fpo {
        display: flex;
        align-items: center;
        padding: to-rem(10px) 0;

        &:hover {
            box-shadow: 0 2px #e3e3e3;
            cursor: pointer;
        }

        .sparky-c-text-passage__inner {
            line-height: to-rem(20px);
        }
    }

    &__loader {
        position: absolute;
        top: 30%;
        right: 5%;
    }
}

.sparky-c-select-field__status-icon.sparky-c-icon.sparky-c-icon--lg {
    width: to-rem(20px);
    height: to-rem(20px);
}
