@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.find-store-near-me {
    .sparky-c-button {
        &__text {
            line-height: to-rem(20px);
        }
    }

    &__selections {
        padding: to-rem(16px);
        padding-top: 0;
        min-height: to-rem(85px);

        &-no-store {
            min-height: to-rem(50px);
            height: to-rem(50px);
        }
    }

    &__filter {
        > fieldset {
            margin-bottom: 0 !important;
        }

        button {
            margin-left: to-rem(33px);
        }
    }

    .sparky-c-accordion-panel {
        &__button {
            padding: to-rem(24px);
        }
    }
}

.sparky-c-button {
    &__footer {
        line-height: 5;
    }
}
