.store-tooltip {
    &__address {
        .sparky-c-button__text {
            color: var(--sparky-color-neutral-900);
            font-weight: var(--sparky-font-weight-medium);
            text-decoration: underline;
        }

        .sparky-c-tooltip__content {
            font-family: var(--sparky-font-family-primary);
            padding: calc(var(--sparky-size-base-unit) * 1);
            left: 50%;
        }
    }
}
