@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '~common-next/styles/functions';

.no-search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--sparky-font-family-primary);
    margin-bottom: to-rem(50px);
    padding-top: to-rem(64px);

    a {
        text-decoration: none;
    }

    @media all and (max-width: $sparky-bp-md) {
        .help-message-container {
            text-align: center;
        }
    }

    .message-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: to-rem(24px);
    }

    .no-search-item {
        font-size: var(--sparky-font-size-14);

        &__query {
            font-size: var(--sparky-font-size-20);
            font-weight: var(--sparky-font-weight-bold);
            margin-top: 0;
            margin-bottom: to-rem(8px);

            @media all and (max-width: $sparky-bp-md) {
                font-size: var(--sparky-font-size-18);
            }
        }

        &__message {
            display: flex;
            flex-direction: column;
            margin-top: 0;
            margin-bottom: 0;
            text-align: center;
        }

        &__link {
            color: var(--sparky-theme-color-primary-background);
        }
    }
}
