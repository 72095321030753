@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.filter-container {
    @media screen and (max-width: $sparky-bp-sm-2) {
        overflow-x: auto;
    }

    .sparky-c-toolbar {
        padding: to-rem(16px) 0;
    }

    .sparky-c-button {
        height: to-rem(32px);
    }

    .sparky-c-button-dropdown__panel {
        padding: size(2) 0;
    }

    .sparky-c-button--toolbar {
        line-height: to-rem(20px);
    }

    &__label-filter {
        margin-left: size(0.5);
    }

    &__sorting-product {
        display: none;

        @media screen and (min-width: $sparky-bp-md) {
            display: block;
        }

        .sparky-c-dropdown {
            width: size(25);

            @media screen and (min-width: $sparky-bp-lg) {
                left: auto;
                right: 0;
            }
        }

        .sparky-c-button {
            justify-content: space-between;
            border-color: #b2b2b2;

            &__text {
                color: var(
                    --sparky-theme-color-neutral-subtle-foreground-inverted
                );
                line-height: to-rem(8px);

                .selected-text {
                    font-weight: var(--sparky-font-weight-medium);
                }
            }
        }
    }

    &__button-dropdown {
        &.all-filters,
        &:has(.filter-refinement-brand--selected),
        &:has(.filter-refinement-store--selected),
        &:has(.custom-filter-refinement-brand--selected),
        &:has(.custom-filter-refinement-store--selected),
        &:has(.filter-choice__border--selected),
        &:has(.filter-by-rating__item-container--item__checked),
        &:has(.range-slider--selected) {
            border-color: var(--sparky-color-brand-blue-500);
            border-width: var(--sparky-border-width-md);
            width: auto !important;

            .sparky-c-button {
                color: var(--sparky-theme-color-primary-foreground);
                border-color: var(--sparky-color-brand-blue-500);
                border-width: var(--sparky-border-width-md);

                &__icon {
                    color: var(--sparky-theme-color-primary-foreground);
                }

                &__text {
                    color: var(--sparky-theme-color-primary-foreground);
                    font-weight: var(--sparky-font-weight-medium);
                }
            }
        }

        &.filter-rating {
            .sparky-c-button-dropdown__panel {
                @media screen and (min-width: $sparky-bp-lg) {
                    width: to-rem(375px);
                    height: to-rem(326px);
                    padding-top: to-rem(20px);
                }
            }
        }

        &.filter-range {
            .sparky-c-button-dropdown__panel {
                @media screen and (min-width: $sparky-bp-lg) {
                    width: to-rem(375px);
                    height: to-rem(249px);
                }
            }
        }

        &.list-filter {
            width: to-rem(126px);

            @media screen and (min-width: $sparky-bp-lg) {
                width: to-rem(109px);
            }
        }

        &.store-filter {
            .sparky-c-button-dropdown__button {
                width: to-rem(141px);
            }

            .sparky-c-button-dropdown__panel {
                @media screen and (min-width: $sparky-bp-lg) {
                    width: to-rem(375px);
                }
            }
        }

        &.brand-filter {
            .sparky-c-button-dropdown__panel {
                @media screen and (min-width: $sparky-bp-lg) {
                    width: to-rem(375px);
                }
            }
        }
    }
}

.filter-wrapper {
    padding: 0;

    .sparky-c-toolbar__list-container {
        overflow-x: auto;
        white-space: nowrap;
        max-width: calc(100vw);
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }

        @media screen and (min-width: $sparky-bp-md) {
            overflow-x: inherit;
        }
    }

    .sparky-c-toolbar__list {
        flex-wrap: nowrap;
    }

    &__item {
        width: 100%;
        display: none;

        &--show {
            display: block;
        }
    }

    &--desktop,
    &--tablet {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    &--mobile {
        width: 100vw;
        margin-left: -4vw;

        .sparky-c-toolbar__list {
            padding-left: 4vw;
        }
    }
}

.filter-container__drawer-filter .sparky-c-drawer__window {
    height: 100%;

    .sparky-c-icon--x {
        height: to-rem(16px);
        width: to-rem(16px);
    }

    &:has(.sparky-c-drawer__body) {
        .sparky-c-icon--chevron-down {
            height: to-rem(24px);
            width: to-rem(20px);
        }

        .sparky-c-button--link {
            padding-right: to-rem(16px);
        }

        .sparky-c-drawer__header {
            height: to-rem(64px);
        }

        .sparky-c-drawer__footer {
            padding-bottom: to-rem(32px);
        }

        .clear-filters {
            .clear-filters__header {
                padding-bottom: to-rem(24px);
                padding-top: to-rem(24px);
                height: to-rem(32px);
            }

            .sparky-c-icon--x-circle {
                width: to-rem(14px);
                height: to-rem(14px);
            }
        }

        &:has(.filter-drawer-container) {
            .sparky-c-drawer__header {
                @media screen and (max-width: $sparky-bp-md) {
                    height: to-rem(64px);
                    padding-top: to-rem(21px);
                    padding-bottom: to-rem(20px);
                }

                .sparky-c-drawer__header-content {
                    order: 2;
                    margin-right: auto;
                    flex-grow: 4;
                    text-align: center;

                    .sparky-c-heading--title {
                        @media screen and (max-width: $sparky-bp-md) {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: to-rem(23px);
                            line-height: to-rem(28px);
                            padding-right: 32px;
                            font-size: var(--sparky-font-size-18) !important;
                        }
                    }
                }

                .sparky-c-drawer__header-right {
                    .sparky-c-drawer__close-button {
                        border-radius: 50%;
                        background-color: var(--sparky-color-neutral-50);
                        padding: size(1);
                        transition: background var(--sparky-anim-fade-quick)
                            var(--sparky-anim-ease);
                        height: to-rem(28px);
                        width: to-rem(28px);

                        .sparky-c-button__icon {
                            height: size(1.5);
                            width: size(1.5);
                        }
                    }

                    .drawer-filter__button-clear-filters {
                        top: to-rem(22px);
                        display: flex;
                        align-items: center;
                        background-color: transparent;
                        border: none;
                        font-family: var(--sparky-font-family-primary);
                        font-weight: var(--sparky-font-weight-regular);
                        font-size: var(--sparky-font-size-16);
                        color: var(--sparky-color-brand-blue-500);
                        height: to-rem(20px);
                        line-height: to-rem(24px);
                    }
                }
            }

            .sparky-c-drawer__body {
                @media screen and (max-width: $sparky-bp-md) {
                    padding-bottom: to-rem(16px);

                    .find-store-near-me__selections {
                        margin-bottom: size(4);
                    }
                }
            }
        }
    }

    .sparky-c-heading {
        padding-top: to-rem(12px);
        padding-bottom: to-rem(12px);
    }

    .sparky-c-checkbox-field {
        &__body {
            margin-bottom: to-rem(16px);
        }
    }

    .sparky-c-button {
        &__text {
            line-height: 24px;
        }
    }

    .c-store-listing-card__distance {
        padding-top: to-rem(16px);
    }

    .price-tool-bar .price-range__actions--clear-button {
        @media screen and (max-width: $sparky-bp-md) {
            display: none;
        }
    }
}
