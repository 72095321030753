@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.clear-filters {
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding: size(2) 0;
    border-bottom: to-rem(1px) solid var(--sparky-color-neutral-200);

    @media screen and (max-width: $sparky-bp-md) {
        width: 100vw;
        margin-left: -4vw;
    }

    &__header {
        padding: size(1.5) 0;
        padding-top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__content {
        overflow-x: auto;
        white-space: nowrap;
        max-width: 100vw;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .sparky-c-button__text {
        margin-top: calc(var(--sparky-size-base-unit) * 0.7);
    }

    .sparky-c-chips {
        flex-wrap: wrap;

        @media screen and (max-width: $sparky-bp-md) {
            flex-wrap: nowrap;
            padding-left: 4vw;
        }

        .sparky-c-chips__button {
            height: to-rem(32px);
            padding: to-rem(7px) to-rem(8px) to-rem(7px) to-rem(12px);
            color: var(--sparky-theme-color-neutral-bold-foreground);
            border-radius: to-rem(16px);

            .sparky-c-chips__text {
                line-height: to-rem(20px);
            }
        }
    }
}
