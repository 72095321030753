@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/breakpoints';

$breadcrumbs-desktop-min: 81.25rem;

.plp-breadcrumbs__container {
    margin: 0;
    margin-top: to-rem(27px);

    @media only screen and (min-width: $breadcrumbs-desktop-min) {
        margin-left: calc((100vw - $breadcrumbs-desktop-min) / 2);
    }

    @media only screen and (max-width: $sparky-bp-sm-2) {
        margin-top: to-rem(25px);
    }

    max-width: 100%;
    white-space: nowrap;
}
