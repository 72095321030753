@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '~common-next/styles/functions';

.visual-nav-section {
    width: 100%;
    overflow: hidden;

    @media screen and (max-width: $sparky-bp-sm-2) {
        overflow: visible;
    }
}

.visual-navigation-section {
    overflow: hidden;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100%;

    @media screen and (max-width: $sparky-bp-sm-2) {
        overflow: visible;
    }

    .sparky-c-section__header {
        &:has(h2:empty) {
            display: none;
        }
    }
}

.visual-navigation-container {
    overflow: hidden;
    width: 100%;

    .sparky-c-picture__image {
        max-width: 150px;
        object-fit: contain;
        margin: auto;
    }

    .sparky-c-tile {
        margin: auto;
    }

    @media screen and (max-width: $sparky-bp-sm-2) {
        overflow: visible;
        padding-left: 0;

        .sparky-c-horizontal-overflow-carousel__inner {
            scroll-behavior: smooth;
        }

        .sparky-c-horizontal-overflow-carousel__list {
            position: relative;
            left: to-rem(16px);
        }
    }
}
