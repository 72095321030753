@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '~common-next/styles/functions';

.price-range {
    position: relative;
    width: 100%;
    background-color: var(--sparky-color-neutral-white);
    padding: to-rem(12px) to-rem(16px) 0 to-rem(16px);
    font-family: var(--sparky-font-family-primary);
    font-size: var(--sparky-font-size-14);

    @media screen and (max-width: $sparky-bp-sm-2) {
        padding: 0 to-rem(16px) 0 to-rem(16px);
    }

    .range-values {
        position: relative;
        width: 100%;
        height: to-rem(20px);
        margin-top: to-rem(16px);
        margin-bottom: to-rem(16px);

        &--input-range {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 100%;
            outline: none;
            position: absolute;
            margin: auto;
            top: 0;
            bottom: to-rem(2px);
            background-color: transparent;
            pointer-events: none;

            &::-webkit-slider-runnable-track {
                -webkit-appearance: none;
                height: to-rem(5px);
            }

            &::-moz-range-track {
                -moz-appearance: none;
                height: to-rem(5px);
            }

            &::-ms-track {
                appearance: none;
                height: to-rem(5px);
            }

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: to-rem(20px);
                width: to-rem(20px);
                background-color: var(--sparky-color-brand-blue-500);
                cursor: pointer;
                margin-top: to-rem(-9px);
                pointer-events: auto;
                border-radius: 50%;
            }

            &::-moz-range-thumb {
                -webkit-appearance: none;
                height: to-rem(20px);
                width: to-rem(20px);
                cursor: pointer;
                border-radius: 50%;
                background-color: var(--sparky-color-brand-blue-500);
                pointer-events: auto;
                border: none;
            }

            &::-ms-thumb {
                appearance: none;
                height: to-rem(20px);
                width: to-rem(20px);
                cursor: pointer;
                border-radius: 50%;
                background-color: var(--sparky-color-brand-blue-500);
                pointer-events: auto;
            }

            &:active::-webkit-slider-thumb {
                background-color: var(--sparky-color-neutral-white);
                border: to-rem(1px) solid var(--sparky-color-brand-blue-500);
            }

            &__has-max-range {
                z-index: 10;
            }
        }

        .slider-track {
            width: 100%;
            height: to-rem(5px);
            position: absolute;
            margin: auto;
            top: to-rem(-6px);
            bottom: 0;
            border-radius: to-rem(5px);
        }
    }

    .header-values {
        display: flex;
        align-items: center;
        justify-content: center;

        &--range {
            margin: 0;
            font-size: var(--sparky-font-size-18);
            line-height: to-rem(20px);
        }
    }

    .footer-values {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: var(--sparky-font-family-primary);
        font-size: var(--sparky-font-size-14);
        line-height: to-rem(20px);
        padding: 0 to-rem(6px) to-rem(16px);

        @media screen and (max-width: $sparky-bp-sm-2) {
            padding-bottom: to-rem(74px);
            border-bottom: to-rem(1px) solid var(--sparky-color-neutral-200);
        }
    }

    &__clear-drawer-button {
        outline: none;
        position: absolute;
        top: calc(var(--sparky-size-base-unit) * -7.75);
        right: to-rem(50px);
        padding-right: 0 !important;
        width: auto;

        &:disabled {
            background-color: transparent;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        &--clear-button {
            padding: to-rem(16px);
            height: to-rem(50px) !important;
            text-decoration: none !important;
            outline: none;

            &:disabled {
                background-color: transparent;
            }

            &.has-border {
                border-top: to-rem(1px) solid var(--sparky-color-neutral-200) !important;
            }

            .sparky-c-button__text {
                height: to-rem(18px) !important;
                line-height: to-rem(20px);
            }

            @media screen and (max-width: $sparky-bp-sm-2) {
                display: none;
            }
        }

        &--view {
            height: to-rem(48px) !important;

            .sparky-c-button__text {
                font-size: var(--sparky-font-size-16);
                line-height: to-rem(24px);
                color: var(
                    --sparky-theme-color-button-primary-foreground
                ) !important;
            }

            @media screen and (max-width: $sparky-bp-sm-2) {
                margin-top: to-rem(16px);
            }
        }
    }
}

.filter-wrapper__item {
    .price-range {
        .footer-values {
            border-bottom: none !important;
        }
    }
}
