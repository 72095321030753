@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@app/acp/globalStyles/account.vars';

.payment-form {
    width: 100%;

    > .sparky-c-heading--body-lg-bold {
        margin: to-rem(12px) 0 to-rem(13px);

        @media all and (min-width: $desktop-min) {
            margin: to-rem(28px) 0 to-rem(13px);
        }
    }

    &__subtitle.sparky-c-heading--body-lg-bold {
        margin-bottom: to-rem(16px);

        @media all and (min-width: $desktop-min) {
            margin-bottom: to-rem(16px);
        }
    }

    .sparky-c-checkbox-field {
        margin-top: to-rem(12px);
    }

    .sparky-c-checkbox-field,
    .sparky-c-checkbox-field__body {
        margin-bottom: 0 !important;
    }

    &__remove-button {
        margin: to-rem(26px) 0 0;
        display: flex;
        justify-content: center;

        .sparky-c-button {
            font-size: var(--sparky-font-size-16);
            color: var(--sparky-color-brand-red-700);
        }
    }

    &__edit-form {
        &--card-info {
            display: flex;
            align-items: center;

            .card-name {
                margin-left: $margin-md;
            }
        }
    }

    &__error-banner {
        margin-top: $margin-lg !important;

        .sparky-c-text-passage {
            padding-right: 0;
        }
    }
}
