@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.filter-refinement-list {
    font-family: var(--sparky-font-family-primary);
    font-size: var(--sparky-font-size-12);
    position: relative;

    @media screen and (max-width: $sparky-bp-md) {
        max-height: calc(100vh - 185px);
    }

    &__search-box {
        @media screen and (min-width: $sparky-bp-md) {
            padding-left: size(2);
            padding-right: size(1.5);
        }

        position: absolute;
        width: calc(100% - size(0.5));
        background-color: var(--sparky-color-neutral-white);
        z-index: 1;

        input {
            font-family: var(--sparky-font-family-primary);
            font-size: var(--sparky-font-size-16);
            color: var(--sparky-color-neutral-900);
            width: 100%;
            padding: size(1.5) size(2);
            border-radius: var(--sparky-border-radius-md);
            border: solid var(--sparky-border-width-md)
                var(--sparky-color-neutral-200);

            &:hover,
            &:focus,
            &:focus-visible {
                outline: none;
                border: solid var(--sparky-border-width-md)
                    var(--sparky-color-other-focus-3);
            }

            &::placeholder {
                color: var(--sparky-color-neutral-900);
            }

            &::-webkit-search-cancel-button {
                display: none;
            }
        }

        button {
            display: none;
        }
    }

    &__container {
        padding: 0;
        flex-direction: column;
        overflow-y: auto;
        margin-top: 0;
        list-style: none;

        li {
            list-style: none;
        }

        &--has-show-more {
            &::-webkit-scrollbar-thumb {
                display: none;
            }
        }

        &--has-search-box {
            padding-top: to-rem(63px) !important;
        }

        &::-webkit-scrollbar {
            width: size(0.5);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(100 100 100 / 80%);
            border-radius: var(--sparky-border-radius-md);
        }
    }

    &__no-results {
        padding: 20px 12px;
        text-align: center;
        font-size: var(--sparky-font-size-14);

        @media screen and (max-width: $sparky-bp-md) {
            height: calc(100vh - 300px);
            max-height: calc(100vh - 300px);
        }

        &.has-search-box {
            padding-top: 63px !important;
        }
    }

    &__item {
        display: flex;
        padding-top: size(0.5);
        padding-bottom: size(0.5);
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        font-size: var(--sparky-font-size-14);
        color: var(--sparky-color-neutral-900);

        @media screen and (min-width: $sparky-bp-md) {
            padding-left: size(1.5);
            padding-right: size(1.5);
        }

        &--selected {
            margin-right: to-rem(7px);
            width: size(2.5);
            height: size(2.5);
        }

        &--counter {
            padding-left: size(0.5);

            @media screen and (max-width: $sparky-bp-sm-2) {
                margin-left: 0;
            }

            &::before {
                content: ' (';
            }

            &::after {
                content: ')';
            }
        }

        &:hover {
            background-color: var(--sparky-color-brand-blue-50);
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--sparky-color-neutral-100);
        padding: size(2) 0 0;
        position: relative;
        justify-content: flex-end;

        @media screen and (min-width: $sparky-bp-md) {
            padding-left: size(1.75);
            padding-right: size(1.75);
        }

        &::after {
            content: ' ';
            position: absolute;
            top: -1px;
            right: calc(size(2) * -1);
            width: size(2);
            height: 1px;
            background-color: var(--sparky-color-neutral-100);

            @media screen and (min-width: $sparky-bp-md) {
                display: none;
            }
        }

        &::before {
            content: ' ';
            position: absolute;
            top: -1px;
            left: calc(size(2) * -1);
            width: size(2);
            height: 1px;
            background-color: var(--sparky-color-neutral-100);

            @media screen and (min-width: $sparky-bp-md) {
                display: none;
            }
        }

        &--reset {
            border-radius: var(--sparky-theme-border-radius);
            font-size: var(--sparky-font-size-14);
            color: var(--sparky-color-neutral-600);
            display: none;

            &:focus,
            &:hover {
                outline: none;
                text-decoration: none;
            }

            @media screen and (min-width: $sparky-bp-md) {
                display: flex;
            }
        }

        &--view {
            height: to-rem(48px) !important;

            .sparky-c-button__text {
                color: var(
                    --sparky-theme-color-button-primary-foreground
                ) !important;
                font-size: to-rem(16px);
                line-height: to-rem(24px);
            }
        }

        .sparky-c-button--link:disabled {
            background-color: transparent;
        }
    }

    &__show-more {
        font-size: var(--sparky-font-size-14);
        line-height: to-rem(24px);

        &:focus {
            outline: none;
        }
    }

    /* stylelint-disable selector-class-pattern -- custom classes from react-instantsearch component */
    .ais-RefinementList {
        &.non-drawer-filter {
            max-height: calc(100vh - 300px);
            overflow: hidden;
            overflow-y: scroll;
        }

        .ais-RefinementList-searchBox {
            .ais-SearchBox {
                .ais-SearchBox-form {
                    .ais-SearchBox-input {
                        font-size: var(--sparky-font-size-16);
                        padding: to-rem(14px) to-rem(16px);
                        line-height: to-rem(24px);
                        height: to-rem(56px);
                    }

                    &:has(input:not(:placeholder-shown)) {
                        .ais-SearchBox-input {
                            padding: to-rem(23px) to-rem(16px) to-rem(13px);
                            border-color: var(--sparky-color-brand-blue-500);
                        }

                        .ais-SearchBox-loadingIndicator {
                            display: block;

                            &::after {
                                content: 'Search brands';
                                font-size: var(--sparky-font-size-12);
                                height: to-rem(15px);
                                line-height: to-rem(16px);
                                color: var(--sparky-color-brand-blue-500);
                                top: to-rem(8px);
                                position: absolute;
                                left: to-rem(32px);
                            }

                            .ais-SearchBox-loadingIcon {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .ais-Highlight-highlighted {
            background-color: transparent;
        }

        &:has(.ais-RefinementList-noResults) {
            .ais-RefinementList-searchBox {
                .ais-SearchBox-form {
                    .ais-SearchBox-input {
                        color: #000000de !important;
                        border-color: var(
                            --sparky-color-neutral-400
                        ) !important;
                    }

                    .ais-SearchBox-loadingIndicator {
                        &::after {
                            color: var(--sparky-color-neutral-900) !important;
                        }
                    }
                }
            }
        }
    }

    &.brand {
        .filter-refinement-list__container {
            @media screen and (max-width: $sparky-bp-md) {
                height: calc(100vh - 250px);
                max-height: calc(100vh - 250px);
            }

            @media screen and (min-width: $sparky-bp-lg) {
                min-height: to-rem(278px);
            }
        }

        .filter-refinement-list {
            &:has(.ais-RefinementList-showMore--disabled) {
                .filter-refinement-list__container {
                    &.drawer-filter {
                        min-height: auto;
                    }
                }
            }
        }

        .filter-refinement-list__show-more {
            &.ais-RefinementList-showMore--disabled {
                display: none;
            }
        }
    }
}
