@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '~common-next/styles/functions';

.filter-by-rating {
    font-family: var(--sparky-font-family-primary);
    font-size: var(--sparky-font-size-14);

    &__item-container {
        @media screen and (max-width: $sparky-bp-md) and (min-width: $sparky-bp-sm-2) {
            padding: 0 to-rem(16px);
        }

        @media screen and (max-width: $sparky-bp-md) {
            display: flex;
            flex-direction: column;
            height: to-rem(162px);
            justify-content: space-between;
        }

        &--item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: to-rem(7px) to-rem(18px);
            cursor: pointer;
            width: 100%;
            background-color: transparent;
            border: none;
            font-family: var(--sparky-font-family-primary);
            font-size: var(--sparky-font-size-14);
            color: var(--sparky-color-neutral-900);
            line-height: to-rem(20px);

            &__checked {
                font-weight: var(--sparky-font-weight-medium);
            }

            @media screen and (max-width: $sparky-bp-md) {
                padding: 0;
                height: to-rem(20px);
            }
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        padding: 0 to-rem(16px);

        @media screen and (max-width: $sparky-bp-sm-2) {
            padding: to-rem(46px) 0 0;

            .divider {
                position: relative;
                left: to-rem(-16px);
                width: calc(100vw - to-rem(2px));
            }
        }

        &--reset {
            border-radius: var(--sparky-theme-border-radius);
            font-size: var(--sparky-font-size-14);
            display: none;

            &:focus {
                outline: none;
            }

            &:disabled {
                background-color: transparent;
            }

            @media screen and (min-width: $sparky-bp-md) {
                display: flex;
            }
        }

        &--view {
            height: to-rem(48px) !important;

            .sparky-c-button__text {
                color: var(
                    --sparky-theme-color-button-primary-foreground
                ) !important;
                font-size: var(--sparky-font-size-16);
                line-height: to-rem(24px);
            }
        }
    }
}
