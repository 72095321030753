@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@app/acp/globalStyles/account.vars';

.billing-address {
    .sparky-c-heading--body-lg-bold {
        margin: to-rem(30px) 0 to-rem(14px);
    }

    .sparky-l-grid.sparky-u-margin-bottom-lg {
        margin-bottom: $margin-sm !important;
    }

    .sparky-c-loading-indicator {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__button-group {
        margin: to-rem(14px) 0 0;

        @media all and (min-width: $desktop-min) {
            margin: 0;
        }
    }

    &__save-cta {
        height: to-rem(48px);
    }
}
